<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import { apiRequest } from "@/helpers/api-call";

export default {
  page: {
    title: "Historiques des Virements",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: { Layout, PageHeader },
  data() {
    return {
      title: "Historiques des virements",
      items: [
        {
          text: "Dashboard",
          href: "/",
        },
        {
          text: "Virements",
          active: true,
        },
      ],
      enAttentes: 0,
      effectues: 0,
      order: [],
      ordersData: [],
      totalRows: 1,
      currentPage: 1,
      perPage: 100,
      pageOptions: [100, 250, 500],
      filter: null,
      filterOn: [],
      sortBy: "orderid",
      sortDesc: false,
      fields: [
        { key: "trajet", sortable: true, label: "Trajet" },
        { key: "conducteur", sortable: true, label: "Conducteur" },
        { key: "place", sortable: true, label: "Places" },
        { key: "depart", sortable: true, label: "Départ" },
        { key: "montant", sortable: true, label: "Montant" },
        { key: "action" },
      ],
      field: [
        { key: "trajet", sortable: true, label: "Trajet" },
        { key: "conducteur", sortable: true, label: "Conducteur" },
        { key: "montant", sortable: true, label: "Montant viré" },
        { key: "date", sortable: true, label: "Date virement" },
        { key: "auto", sortable: true, label: "Mode" },
        { key: "admin", label: "Admin" },
      ],
      showConfirm: false,
      idVirement: 0,
      showManuel:false,
      showSucessVirement: false,
      showEchecVirement: false,
    };
  },
  mounted() {
    this.init();
  },

  methods: {
    async init() {
      this.order = [];
      this.ordersData = [];
      
      const historique = await apiRequest(
        "GET",
        "admin/virements",
        undefined,
        false
      );
      console.log("historique:", historique.data);

      if (historique && historique.data) {
        this.enAttentes = historique.data.enAttentes;
        this.effectues = historique.data.effectues;

        //var listeVirementEnAttentes = new Array();
        //var listeVirementEffectues;

        historique.data.virements.forEach((element) => {
          var virement = {
            idVirement: element.id,
            trajet:
              element.trajet.lieuDepart.nom +
              " >> " +
              element.trajet.lieuArrivee.nom,
            conducteur:
              element.conducteur.personne.lastname +
              " " +
              element.conducteur.personne.firstname,

            date:
              element.dateVirement == null
                ? ""
                : new Date(element.dateVirement).toLocaleString("fr-FR"),
            montant: element.montant,
            moyen: element.moyen,
            depart: new Date(element.trajet.dateHeureDepart).toLocaleString(
              "fr-FR"
            ),
            place: element.place,
            about: element.statut,
            admin:
              element.admin === undefined
                ? ""
                : element.admin.personne.lastname +
                  " " +
                  element.admin.personne.firstname,
            auto: element.isAuto == true ? "AUTO" : "MANUEL"
          };
          if (element.statut == "PAID") {
            this.order.push(virement);
          }
          if (element.statut == "PENDING") {
            this.ordersData.push(virement);
          }
        });

        //this.transactionData = TransactListTable
      }
    },
    confirmeVirer(row) {
      //console.log('row:', row)
      this.idVirement = row.idVirement;
      console.log("this.idVirement:", this.idVirement);
      this.showConfirm = true;
    },
    confirmeVirerM(row) {
      //console.log('row:', row)
      this.idVirement = row.idVirement;
      console.log("this.idVirement:", this.idVirement);
      this.showManuel = true;
    },
    async virer() {
      this.showConfirm = false;
      console.log("this.idVirement:", this.idVirement);

      const virer = await apiRequest(
        "POST",
        "admin/virer",
        {idVirement: this.idVirement},
        false
      );
      console.log("virer:", virer.data);

      if (virer && virer.data) {
        this.showSucessVirement = true;
        this.init();
      } else {
        this.showEchecVirement = true;
        this.init(); 
      }
    },

    async virementManuel() {
      this.showManuel = false;
      console.log("this.idVirement:", this.idVirement);

      const virer = await apiRequest(
        "POST",
        "admin/virer-manuel",
        {idVirement: this.idVirement},
        false
      );
      console.log("virer:", virer.data);

      if (virer && virer.data) {
        this.showSucessVirement = true;
        this.init();
      } else {
        this.showEchecVirement = true;
        this.init(); 
      }
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-md-12">
        <div class="card">
          <div class="card-body">
            <div class="card-title">
              <h5></h5>
            </div>
            <div class="row">
              <div class="col-md-6">
                <b-card
                  header-class="bg-transparent border-primary"
                  class="border border-primary"
                >
                  <template v-slot:header>
                    <h5 class="my-0 mb-4">
                      Virements en attente
                    </h5>
                  </template>
                  <div class=" float-right">
                    <h1 class="text-primary">{{ this.enAttentes }}</h1>
                  </div>
                </b-card>
              </div>
              <div class="col-md-6">
                <b-card
                  header-class="bg-transparent border-success"
                  class="border border-success"
                >
                  <template v-slot:header>
                    <h5 class="my-0 mb-4">
                      Virements effectués
                    </h5>
                  </template>
                  <div class=" float-right">
                    <h1 class="text-primary">{{ this.effectues }}</h1>
                  </div>
                </b-card>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <div class="card">
          <div class="card-body">
            <div class="card-title">
              <h5>Virements en attente</h5>
            </div>
            <b-tabs nav-class="nav-tabs-custom">
              <b-tab title-link-class="p-3">
                <div class="row mt-4">
                  <div class="col-sm-12 col-md-6">
                    <div id="tickets-table_length" class="dataTables_length">
                      <label class="d-inline-flex align-items-center">
                        Afficher&nbsp;
                        <b-form-select
                          v-model="perPage"
                          size="sm"
                          :options="pageOptions"
                        ></b-form-select
                        >&nbsp;entrées
                      </label>
                    </div>
                  </div>
                  <!-- Search -->
                  <div class="col-sm-12 col-md-6">
                    <div
                      id="tickets-table_filter"
                      class="dataTables_filter text-md-right"
                    >
                      <label class="d-inline-flex align-items-center">
                        Rechercher:
                        <b-form-input
                          v-model="filter"
                          type="search"
                          class="form-control form-control-sm ml-2"
                        ></b-form-input>
                      </label>
                    </div>
                  </div>
                  <!-- End search -->
                </div>
                <div class="table-responsive">
                  <b-table
                    class="table-centered"
                    :items="ordersData"
                    :fields="fields"
                    responsive="sm"
                    :per-page="perPage"
                    :current-page="currentPage"
                    :sort-by.sync="sortBy"
                    :sort-desc.sync="sortDesc"
                    :filter="filter"
                    :filter-included-fields="filterOn"
                    @filtered="onFiltered"
                  >
                    <template v-slot:cell(paymentstatus)="row">
                      <div
                        class="badge font-size-12"
                        :class="{
                          'badge-soft-danger': `${row.value}` === 'Chargeback',
                          'badge-soft-success': `${row.value}` === 'Paid',
                          'badge-soft-warning': `${row.value}` === 'Unpaid',
                        }"
                      >
                        {{ row.value }}
                      </div>
                    </template>
                    <template v-slot:cell(invoice)>
                      <button class="btn btn-light btn-rounded">
                        Invoice
                        <i class="mdi mdi-download ml-2"></i>
                      </button>
                    </template>
                    <template v-slot:cell(action)="row">
                      <a
                        href="javascript:void(0);"
                        class=" btn btn-success mr-3"
                        v-b-tooltip.hover
                        title="Virement"
                        @click="confirmeVirer(row.item)"
                      >
                        Auto
                      </a>
                      <a
                        href="javascript:void(0);"
                        class=" btn btn-warning mr-3"
                        v-b-tooltip.hover
                        title="Virement"
                        @click="confirmeVirerM(row.item)"
                      >
                        Manuel
                      </a>
                    </template>
                  </b-table>
                </div>
                <div class="row">
                  <div class="col">
                    <div
                      class="dataTables_paginate paging_simple_numbers float-right"
                    >
                      <ul class="pagination pagination-rounded mb-0">
                        <!-- pagination -->
                        <b-pagination
                          v-model="currentPage"
                          :total-rows="rows"
                          :per-page="perPage"
                        ></b-pagination>
                      </ul>
                    </div>
                  </div>
                </div>
              </b-tab>
            </b-tabs>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <div class="card">
          <div class="card-body">
            <div class="card-title">
              <h5>Virements effectués</h5>
            </div>
            <b-tabs nav-class="nav-tabs-custom">
              <b-tab title-link-class="p-3">
                <div class="row mt-4">
                  <div class="col-sm-12 col-md-6">
                    <div id="tickets-table_length" class="dataTables_length">
                      <label class="d-inline-flex align-items-center">
                        Afficher&nbsp;
                        <b-form-select
                          v-model="perPage"
                          size="sm"
                          :options="pageOptions"
                        ></b-form-select
                        >&nbsp;entrées
                      </label>
                    </div>
                  </div>
                  <!-- Search -->
                  <div class="col-sm-12 col-md-6">
                    <div
                      id="tickets-table_filter"
                      class="dataTables_filter text-md-right"
                    >
                      <label class="d-inline-flex align-items-center">
                        Rechercher:
                        <b-form-input
                          v-model="filter"
                          type="search"
                          class="form-control form-control-sm ml-2"
                        ></b-form-input>
                      </label>
                    </div>
                  </div>
                  <!-- End search -->
                </div>
                <div class="table-responsive">
                  <b-table
                    class="table-centered"
                    :items="order"
                    :fields="field"
                    responsive="sm"
                    :per-page="perPage"
                    :current-page="currentPage"
                    :sort-by.sync="sortBy"
                    :sort-desc.sync="sortDesc"
                    :filter="filter"
                    :filter-included-fields="filterOn"
                    @filtered="onFiltered"
                  >
                    <template v-slot:cell(paymentstatus)="row">
                      <div
                        class="badge font-size-12"
                        :class="{
                          'badge-soft-danger': `${row.value}` === 'Chargeback',
                          'badge-soft-success': `${row.value}` === 'Paid',
                          'badge-soft-warning': `${row.value}` === 'Unpaid',
                        }"
                      >
                        {{ row.value }}
                      </div>
                    </template>
                    <template v-slot:cell(action)>
                      <a
                        href="javascript:void(0);"
                        class=" btn btn-success mr-3"
                        v-b-tooltip.hover
                        title="Virement"
                      >
                        <i class="mdi mdi-check font-size-18"></i>
                        Virement
                      </a>
                      
                    </template>
                  </b-table>
                </div>
                <div class="row">
                  <div class="col">
                    <div
                      class="dataTables_paginate paging_simple_numbers float-right"
                    >
                      <ul class="pagination pagination-rounded mb-0">
                        <!-- pagination -->
                        <b-pagination
                          v-model="currentPage"
                          :total-rows="rows"
                          :per-page="perPage"
                        ></b-pagination>
                      </ul>
                    </div>
                  </div>
                </div>
              </b-tab>
            </b-tabs>
          </div>
        </div>
      </div>
    </div>
    <b-modal
      id="modal-backdrop"
      centered
      v-model="showConfirm"
      title="Voulez-vous vraiment faire un virement Automatique à cet utilisateur?"
      title-class="font-18"
      hide-footer
    >
      <div class="row float-right mt-3">
        <b-button variant="danger" @click="showConfirm = false">Non</b-button>
        <b-button variant="success" class="ml-3 mr-4" @click="virer"
          >Oui</b-button
        >
      </div>
    </b-modal>

    <b-modal
      id="modal-backdrop"
      centered
      v-model="showManuel"
      title="Voulez-vous vraiment faire un virement Manuel à cet utilisateur?"
      title-class="font-18"
      hide-footer
    >
      <div class="row float-right mt-3">
        <b-button variant="danger" @click="showManuel = false">Non</b-button>
        <b-button variant="success" class="ml-3 mr-4" @click="virementManuel"
          >Oui</b-button
        >
      </div>
    </b-modal>

       <b-modal
      id="modal-backdrop"
      centered
      v-model="showSucessVirement"
      title="Utilisateur modifié avec succès !!!"
      title-class="font-18"
      hide-footer
    >
      <div class="row float-right mt-3">
        <b-button variant="success" @click="showSucessVirement = false">OK</b-button>
      </div>
    </b-modal>

    <b-modal
      id="modal-backdrop"
      centered
      v-model="showEchecVirement"
      title="La modification a échouée"
      title-class="font-18"
      hide-footer
    >
      <div class="row float-right mt-3">
        <b-button variant="danger" @click="showEchecVirement = false"
          >Reéssayez</b-button
        >
      </div>
    </b-modal>
  </Layout>
</template>

<style></style>